import moment from 'moment'
import Speaker from '@public/images/Speaker.svg'
import Box from '@components/base/Box'
import Text from '@components/base/Text'
import Image from 'next/image'
import DonationPercentage from '@public/percentage-placeholder.png'
import { useTheme } from '@libs/useTheme'
import { useEffect, useState } from 'react'
import DefaultThumbnail from '@public/images/default-thumbnail.png'
import { useRouter } from 'next/router'
import CalendarIcon from '@public/images/CalendarIcon.svg'
import Edit from '@public/images/Edit.svg'
import currencies from '@libs/currencies'
import { getContrastColor, openInNewTab } from '@libs/Utility/util'
import { MediaThumbnail } from '@components/ui/MediaThumbnail'
import {
  getMediaType,
  isValidUrl,
  isVimeoUrl,
  isYoutubeUrl,
} from '@libs/Utility/parsers'
import { useAuth } from '@libs/useAuth'

export interface MediaProps {
  url?: string
  image?: string
  title?: string
  subtitle?: string
  description?: string
  isDonation?: boolean
  type?:
    | 'LANDING'
    | 'ONLINE'
    | 'FUNDRAISER'
    | 'IN_PERSON'
    | 'PARTNER'
    | 'CONTENT'
  organization?: string
  banner?: string
  startTime?: any
  endTime?: any
  eventId?: string
  canEdit?: boolean
  isSpeaker?: boolean
  isPublished?: boolean
  size?: 'normal' | 'large'
  attendees?: number | null
  donors?: number
  goal?: number
  raised?: number
  currency?: string
  className?: string
}

function MediaCard({
  className,
  url,
  image,
  title,
  subtitle,
  description,
  isDonation,
  type = 'LANDING',
  organization,
  banner,
  startTime,
  endTime,
  eventId,
  canEdit,
  isSpeaker = false,
  size,
  attendees,
  donors,
  isPublished,
  goal,
  raised,
  currency,
}: MediaProps) {
  const router = useRouter()
  const { theme } = useTheme()

  const contrastColor = getContrastColor(theme.highlightColour)

  const { push } = useRouter()
  const [bgColor, setBgColor] = useState(theme.foregroundColour)
  const [percentage, setPercentage] = useState(0)
  const [mediaType, setMediaType] = useState<'IMAGE' | 'VIDEO' | undefined>(
    undefined
  )

  const isDarkTheme = theme.type === 'DARK'
  const textColorClass = 'text-forumm-text-deep-teal-blue'

  const { profile } = useAuth()

  const isAdmin = profile?.groups?.includes('forumm-admin')
  const isOrganizer = profile?.groups?.includes('organizer')

  useEffect(() => {
    if (image) {
      getMediaType(image).then((type) => setMediaType(type))
    }
  }, [image])

  useEffect(() => {
    if (raised && goal) {
      setPercentage(Math.min((raised / goal) * 100, 100))
    }
  }, [goal, percentage, raised])

  useEffect(() => {
    if (theme.type === 'DARK') {
      setBgColor(theme.mediaBackgroundColor)
    } else {
      setBgColor(theme.foregroundColour)
    }
  }, [theme.foregroundColour, theme.mediaBackgroundColor, theme.type])

  const handleNavigation = () => {
    if (!url) return
    if (isValidUrl(url)) openInNewTab(url)
    else router.push(url)
  }

  const handleEdit = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    router.push(`/create-event?id=${eventId}`)
  }

  return (
    <Box
      onClick={handleNavigation}
      className={`relative rounded-xl my-6 ml-2 mx-[22px] cursor-pointer w-[85%] max-w-[1500px] sm:w-[23%] pb-6 border border-gray-200 shadow-md ${className}`}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <Box className="w-full relative square rounded-t-xl overflow-hidden">
        {mediaType === 'VIDEO' ? (
          <MediaThumbnail
            className="absolute top-0 left-0 w-full h-full rounded-t-x"
            key=""
            media={image}
          />
        ) : (
          <Box
            className="absolute top-0 left-0 w-full h-full rounded-t-x"
            style={{
              backgroundImage: `url(${
                (image as string) || DefaultThumbnail.src
              })`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundColor: theme.backgroundColour,
            }}
          />
        )}
      </Box>
      <Box className="pb-6 px-2 pt-4 flex flex-col">
        {/* TODO: ask what to do with this, its not contemplated on the design */}
        <Box
          className="-mt-24 rounded-xl p-4 shadow-md"
          style={{
            background: isDarkTheme
              ? 'rgba(0, 0, 0, 0.7)'
              : 'rgba(255, 255, 255, 0.6)',
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
          }}
        >
          <Text className="text-xl font-semibold pb-1 line-clamp-2 mb-4 min-h-[4rem] leading-normal">
            {title}
          </Text>
          <Box className=" pb-4">
            <Box className="text-gray-200 flex items-center mr-4 text-sm">
              <MediaThumbnail
                className="inline-block h-7 w-7 object-cover mr-2 rounded-full"
                key=""
                media={banner}
              />
              <span className={`${textColorClass} font-semibold truncate`}>
                {organization}
              </span>
            </Box>
          </Box>

          {subtitle && (
            <Text className="pb-6 text-sm font-medium line-clamp-2">
              {subtitle}
            </Text>
          )}
          <Text className="text-sm line-clamp-5 h-14 min-h-[5rem] !text-gray-400">
            {description || ''}
          </Text>
          {type !== 'LANDING' &&
            type !== 'FUNDRAISER' &&
            type !== 'CONTENT' &&
            type !== 'PARTNER' && (
              <p className={`py-4 text-sm ${textColorClass}`}>
                <b>{attendees}</b> Participants
              </p>
            )}

          {type === 'FUNDRAISER' && (
            <p className={`py-4 ${textColorClass} text-sm`}>
              <b>{donors}</b> Donors
            </p>
          )}

          {type !== 'LANDING' &&
            type !== 'FUNDRAISER' &&
            type !== 'CONTENT' &&
            type !== 'PARTNER' && (
              <Box className="text-xs cursor-pointer flex items-center ">
                <CalendarIcon
                  className="w-8 h-8 "
                  fill={theme?.textColour}
                  style={{ marginLeft: '-0.5rem' }}
                />
                <span className="flex-1 !text-gray-400">
                  {moment(startTime).format('MMM DD, h:mmA')} {' - '}
                  {moment(endTime).format('MMM DD, h:mmA')}
                </span>
              </Box>
            )}

          {type === 'FUNDRAISER' && !(isOrganizer || isAdmin) && (
            <Box className="h-[32px]"></Box>
          )}

          {type === 'FUNDRAISER' && (isOrganizer || isAdmin) && (
            <Box
              style={{
                opacity: goal || raised ? 1 : 0,
              }}
            >
              <Box className="flex justify-between text-sm">
                <span className={`${textColorClass}`}>
                  <b>{`${currencies[currency ?? 'GBP'].symbol}${
                    !!raised && raised % 1 === 0 ? raised : raised?.toFixed(2)
                  }`}</b>{' '}
                  Raised
                </span>
                <span className={`${textColorClass}`}>
                  <b>{`${currencies[currency ?? 'GBP'].symbol}${goal}`}</b>
                </span>
              </Box>
              <Box
                className={`mt-1 bg-forumm-white ${
                  isDarkTheme ? '' : 'border border-forumm-menu-border'
                } rounded-xl`}
              >
                <Box
                  className={`h-2 rounded-xl ${textColorClass} bg-forumm-blue-light`}
                  style={{
                    width: `${Math.min(percentage, 100)}%`,
                  }}
                ></Box>
              </Box>
            </Box>
          )}

          {isDonation && (
            <Box className="flex items-center mt-6 gap-6">
              <Image
                src={DonationPercentage}
                alt="Donation Percentage"
                width={76}
                height={76}
              />
              <Box className="flex-row opacity-0">
                <Text>€20,000</Text>
                <Text>Raised by 190 supporters</Text>
              </Box>
            </Box>
          )}
        </Box>
        <Box className="absolute bottom-0 left-0 flex justify-between w-full">
          <Box
            show={!!canEdit}
            className={`bg-forumm-blue text-white rounded p-2 px-3 md:px-4 rounded-tl-none rounded-br-none flex items-center -mb-[1px] -mr-[1px] rounded-tr-xl rounded-bl-xl shadow-md text-xs sm:text-sm ${
              isDarkTheme ? '' : 'border border-forumm-menu-border'
            } ${isPublished ? 'bg-green-700' : 'bg-yellow-700'}`}
          >
            <span
              style={{
                color: contrastColor,
              }}
            >
              {isPublished ? 'Published' : 'Draft'}
            </span>
          </Box>
          <Box
            show={isSpeaker}
            className={`bg-forumm-blue text-white rounded p-2 px-3 md:px-4 rounded-tl-none rounded-br-none flex items-center -mb-[1px] -mr-[1px] rounded-tr-xl rounded-bl-xl shadow-md text-xs sm:text-sm ${
              isDarkTheme ? '' : 'border border-forumm-menu-border'
            }`}
            style={{
              backgroundColor: theme.highlightColour,
            }}
          >
            {/* i have to change this texts and buttons */}
            <Speaker className="h-4 w-4 inline-block mr-2 text-FFFFFF" />{' '}
            <span
              style={{
                color: contrastColor,
              }}
            >
              Speaker
            </span>
          </Box>
          <Box
            show={!!type && ['PARTNER', 'CONTENT'].includes(type)}
            onClick={handleNavigation}
            className={`ml-auto py-2 cursor-pointer bg-forumm-blue text-white rounded px-3 md:px-4 rounded-bl-none rounded-tr-none flex items-center -mb-[1px] -mr-[1px] rounded-br-xl rounded-tl-xl shadow-md shadow-md text-xs sm:text-sm ${
              isDarkTheme ? '' : 'border border-forumm-menu-border'
            } hover:animate-heartbeat`}
            style={{
              backgroundColor: theme.highlightColour,
            }}
          >
            {/* i have to change this texts and buttons */}
            {/* <Edit className="h-4 w-4 inline-block mr-2" />{' '} */}
            <span
              style={{
                color: contrastColor,
              }}
            >
              View
            </span>
          </Box>
          <Box
            show={!!canEdit}
            onClick={handleEdit}
            className={`cursor-pointer bg-forumm-blue text-white rounded px-3 md:px-4 rounded-bl-none rounded-tr-none flex items-center -mb-[1px] -mr-[1px] rounded-br-xl rounded-tl-xl shadow-md shadow-md text-xs sm:text-sm ${
              isDarkTheme ? '' : 'border border-forumm-menu-border'
            } hover:animate-heartbeat`}
            style={{
              backgroundColor: theme.highlightColour,
            }}
          >
            {/* i have to change this texts and buttons */}
            <Edit className="h-4 w-4 inline-block mr-2" />{' '}
            <span
              style={{
                color: contrastColor,
              }}
            >
              Edit
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MediaCard
